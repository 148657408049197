.container-specialties {
    display: flex;
    flex-wrap: wrap;
}

.specialties-text {
    padding: 1em;
    color: #1f1e2a;
    font-weight: 900;
}

.specialties-text h3 {
    font-weight: 900;
}

.all-logos {
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 15px;
}

.col-logo {
    padding: 5px;
}

.container-logo {
    padding: 30px;
    background: #528ca7
}

.container-logo img {
    width: 100%;
    height: 73px;
    transition-property: all;
    transition-duration: 0.4s;
}

.container-logo:hover img {
    transform: translate(1px, -15px);
    transition-property: all;
    transition-duration: 0.4s;
}


@media (max-width: 1399px) {
    .container-specialties {
        padding-left: 0;
    }
    
    .all-logos {
        padding-left: 0;
    }
}


@media (max-width: 767px) {
    .container-logo img {
        height: 140px;
    }
}