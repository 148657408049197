.container-background {
    width: 100%;
    height: 100vh;
    background: rgba(16, 65, 100, 0.55);
    background-image: url('../../../../public/img/Imagen-bienvenida.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background-blend-mode: multiply;
    filter: brightness(1.5);
}