.section-aboutme {
    background: #1f1e2a;
    color: #eddac5;
}

.container-img {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 34px;
    padding-left: 26px;
    padding-right: 26px;
}

.container-img img {
    max-width: 1013px;
    width: 100%;
    border-radius: 25px;
    box-shadow: 0px 0px 5px 5px rgba(82,140,167,0.55);
}

.container-span {
    text-align: justify;
    padding-left: 26px;
    padding-right: 26px;
    font-weight: bold;
    line-height: 170%;
}