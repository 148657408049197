.section-home {
    background: #eddac5;
    color: #1f1e2a;
}

.section-home h2 {
    font-weight: 900;
}

.carrousel-home {
    margin-bottom: 10px;
}

.carousel-item {
    background-color: transparent;
}

.img-carrousel {
    margin: 0 auto;
    max-width: 80%;
    max-height: 400px;
}

.total-container h6 {
    line-height: 150%;
    font-weight: 900;
}

.total-container h6 span {
    font-style: italic;
}

.row-grid-home {
    gap: 60px;
    margin-top: 35px;
}

.row-grid-home p {
    line-height: 170%;
}


@media (max-width: 767px) {
    .row-grid-home {
        gap: 10px;
    }
    
    .row-grid-home p {
        line-height: 1.2em;
    }
}